.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;

  @media (--viewport-m) {
    margin: 0 0 24px;
  }
}

.childrenContainer > :not(:last-child) {
  margin: 0 0 16px;

  @media (--viewport-m) {
    margin: 0 0 24px;
  }
}
