.container {
  position: relative;
}

.button {
  background-color: transparent;
  border-radius: var(--border-radius);
  border: solid 1px var(--color-orange);
  color: var(--color-orange);
  cursor: pointer;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-s);
  padding: 8px 12px;
  transition: color var(--transition), background-color var(--transition);

  & svg {
    color: currentcolor;
    height: 12px;
    margin: 0 0 0 8px;
    transition: color var(--transition);
    width: 12px;
  }

  &:hover,
  &:focus {
    background-color: var(--color-orange);
    color: var(--color-white);
  }
}

.buttonInner {
  align-items: center;
  display: flex;
}

.overlay {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: 80vw;
  padding: 16px;
  position: absolute;
  right: 0;
  top: calc(100% + 14px);
  width: 360px;
  z-index: var(--z-index-premium-button-overlay);

  @media (--viewport-xl) {
    left: 50%;
    transform: translate(-50%);
  }

  &::before {
    content: "";
    border-bottom: 8px solid var(--color-white);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    position: absolute;
    right: 16px;
    top: 0;
    transform: translate(0%, -100%);
    width: 0;

    @media (--viewport-xl) {
      transform: translate(-50%, -100%);
      left: 50%;
    }
  }
}
