.formFieldsContainer > :not(:last-child) {
  margin: 0 0 24px;

  @media (--viewport-m) {
    margin: 0 0 60px;
  }
}

.formError {
  color: var(--color-red);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  text-align: center;
  margin-top: 1.5rem;
}

.input {
  appearance: none;
  background: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
  color: var(--color-black);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  outline: 0;
  padding: 12px 20px;
  resize: none;
  transition: color var(--transition);
  width: 100%;

  &:hover,
  &:focus {
    border-color: var(--color-black);
  }

  &:disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  &.hasError {
    border-color: var(--color-red);
    background-color: var(--color-red-light);
  }
}

.inputContainer {
  display: block;
  width: 100%;
}

.inputContainerHeader {
  margin: 0 0 8px;
}

.inputContainerTitle {
  align-items: center;
  color: var(--color-black);
  display: flex;
  font-size: var(--font-size-m);
  justify-content: space-between;
  line-height: var(--line-height-s);
}

.inputContainerDescription {
  color: var(--color-grey-60);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);
  margin: 4px 0 0;
}

.inputContainerChildrenIcon {
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  & svg {
    color: var(--color-grey-50);
    height: 15px;
    width: 15px;
  }

  &.isClickable {
    cursor: pointer;
    pointer-events: auto;

    & svg:hover,
    & svg:focus {
      color: var(--color-grey-60);
    }
  }
}

.inputContainerFooter {
  display: flex;
  justify-content: space-between;
  margin: 12px 0 0;
}

.inputContainerMaxLength {
  color: var(--color-grey-60);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0 0 0 12px;
  flex-shrink: 0;
}

.inputContainerError {
  color: var(--color-red);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}

.buttonContainer {
  margin: 16px 0 0;
  text-align: center;

  @media (--viewport-m) {
    margin: 24px 0 0;
  }
}

.selectBox {
  appearance: none;
  font-size: var(--font-size-m);
  font-family: var(--font-family-body);
}

.inputContainerChildrenContainer {
  position: relative;
}

.optionList {
  margin: 0;
  list-style: none;
  padding: 0;
}

.optionContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  & svg {
    color: var(--color-green);
    height: 60%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    display: none;
  }
}

.optionInput {
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-40);
  cursor: pointer;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  width: 20px;
  transition: border-color var(--transition), box-shadow var(--transition);

  &:hover {
    border-color: var(--color-green);
    box-shadow: var(--box-shadow);

    & + svg {
      display: block;
    }
  }

  &:checked {
    border-color: var(--color-green);
    background: var(--color-green);

    & + svg {
      display: block;
      color: var(--color-white);
    }
  }
}

.checkboxInput {
  border-radius: var(--border-radius);
}

.radioButtonInput {
  border-radius: 50%;
}

.optionLabel {
  color: var(--color-black);
  cursor: pointer;
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  margin: 0 0 0 12px;

  & a {
    color: var(--color-black);
  }
}

.requiredSymbol {
  margin-left: 1rem;
  display: inline-block;
}

.optionLabelDescription {
  color: var(--color-black);
  display: block;
  font-size: var(--font-size-s);
  margin: 8px 0 0 32px;
}

.textNextToLabel {
  display: inline-block;
  color: var(--color-grey-50);
  font-size: var(--font-size-s);
  line-height: var(--line-height-m);

  & a {
    color: var(--color-grey-50);
  }
}
